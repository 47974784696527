// Modules
import { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Button from "../components/Button";
// Stylesheet
import "./Newsletter.css";
// Images
import background from "../assets/student-pictures/Newsletterpic2.png";

/**
 * Displays a PDF the most recent newsletter published by SNNA-LSAMP.
 *
 * IMPORTANT NOTE: When posting PDFs on this page, make sure to use a Google Drive embed link, otherwise it will not work
 * properly. All current and future PDFs should be located within the folder listed below.
 *
 * @see https://drive.google.com/drive/folders/1PrG0ABAhLInMgXrJLCkFjVyJw8dAXUnr?usp=sharing The folder containing all
 * the newsletters.
 * @see https://youtu.be/BcVFQWdXlsM?t=72 To learn how to get the embed link for a Google Drive PDF document. Don't copy the
 * entire <iframe> element, just the src URL that it provides.
 */
export default function Newsletter() {
  // Save the src URL for every newsletter as a const variable like so.
  const NewsletterDec2021 =
    "https://drive.google.com/file/d/1TQHCWlj4YdyPFCZ8_jPiyQvYFz0FMqJK/preview";
  const NewsletterMay2022 =
    "https://drive.google.com/file/d/1iBmt35hF0REG-CFJfEzi9Z-6DOsFIYmx/preview";
  const NewsletterJun2022 = 
    "https://drive.google.com/file/d/1W6OHVr_TNd9vlS08Y9TUDmrmOUDDROZ8/preview";
  const NewsletterSep2022 =
    "https://drive.google.com/file/d/18A7SBeqMQFwobybi5NdWKgl0Cg5z23W0/preview";
  const NewsletterJan2024 =
    "https://drive.google.com/file/d/1cSPUd1o22Lb-ubK2MQVZh3oJPGDbMDUJ/preview";
  const NewsletterMay2024 =
    "https://drive.google.com/file/d/1kL9dNZhnvqw_tGz77gqerINmhjDQEFU8/preview";
  const NewsletterDec2024 =
    "https://drive.google.com/file/d/1gg976RmKODHAHZhl-pbqV-X76ODP0AnD/preview"

  /**
   * Whenever a new issue is added, push the old one to this array using the following object format:
   * {
   *    link: The constant variable from above that contains the link for the newsletter.
   *    name: The name of the newsletter that will be displayed as a clickable link.
   * }
   * NOTE: DO NOT ADD THE MOST RECENT NEWSLETTER TO THIS ARRAY. THE NEWSEST ONE SHOULD BE IN THE IFRAME BELOW.
   */
  // eslint-disable-next-line no-unused-vars
  const [previousIssues, setPreviousIssues] = useState([
    {
      link: NewsletterMay2024,
      name: "May 2024 (Volume 1, Issue 6)",
    },
    {
      link: NewsletterJan2024,
      name: "January 2024 (Volume 1, Issue 5)",
    },
    {
      link: NewsletterSep2022,
      name: "September 2022 (Volume 1, Issue 4)",
    },
    {
      link: NewsletterJun2022,
      name: "June 2022 (Volume 1, Issue 3)",
    },
    {
      link: NewsletterMay2022,
      name: "May 2022 (Volume 1, Issue 2",
    },
    {
      link: NewsletterDec2021,
      name: "December 2021 (Volume 1, Issue 1)",
    },
  ]);

  /**
   * A JavaScript method for opening a link in a new tag.
   * Use this when using the <a> tag is not preferable.
   * @param {string} url The link to the site that will be opened in a new tab.
   */
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <main className='container'>
      <Header
        headerStyle={{
          backgroundPosition: "center 20%",
        }}
        title='Newsletter'
        subheader='Learn more about what goes on in SNNA-LSAMP by reading our newsletter!'
        backgroundImage={background}
      />
      <h1 id='main-content' className='pdf-header'>
        December 2024 Issue
      </h1>
      <iframe
        className='pdf'
        title='SNNA-LSAMP Newsletter Volume 2, Issue 1 (December 2024)' // Be sure to include a title in this format for future newsletters.
        src={NewsletterDec2024} // The most current newsletter should be displayed here.
        width='90%'
        height='800px'
      />
      <div className='previous-issues'>
        <h1 className='pdf-header'>Previous Issues</h1>
        <ul>
          {previousIssues.map((newsletter, index) => (
            <li key={index}>
              <Button
                text={newsletter.name}
                onClick={() => openInNewTab(newsletter.link)}
                variant={index % 2 === 0 ? "yellow" : "blue"}
              />
            </li>
          ))}
        </ul>
      </div>

      <Footer />
    </main>
  );
}
